<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-tree-grid-v2
  AUTHOR : hmlee
  DATE : 2024-11-20
  DESCRIPTION : 트리 컴포넌트 ver2
                트리/컬럼 옵션 값 최소화
-->
<template>
  <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
    <DxCheckBox
      :v-if="treeListConfig.filterUseItem"
      :visible="treeListConfig.filterUseItem.enabled"
      :text="$_msgContents('COMPONENTS.SHOW_VIEW_FL', { value: $_msgContents('COMPONENTS.ITEM', { defaultValue: '항목' }) })"
      :value="treeListConfig.checkedViewFl"
      @initialized="setDefaultCheck"
      @value-changed="handleChangedViewFl"
    />

    <DxTreeList
      id="dxTreeGrid"
      :ref="treeListConfig.refName"
      :width="treeListConfig.width"
      :height="treeListConfig.height"
      :allow-column-reordering="treeListConfig.allowColumnReordering"
      :allow-column-resizing="treeListConfig.allowColumnResizing"
      :auto-expand-all="treeListConfig.autoExpandAll"
      :auto-navigate-to-focused-row="treeListConfig.autoNavigateToFocusedRow"
      :cache-enabled="treeListConfig.cacheEnabled"
      :cell-hint-enabled="treeListConfig.cellHintEnabled"
      :column-auto-width="treeListConfig.columnAutoWidth"
      :column-hiding-enabled="treeListConfig.columnHidingEnabled"
      :column-min-width="treeListConfig.columnMinWidth"
      :column-resizing-mode="treeListConfig.columnResizingMode"
      :customize-columns="treeListConfig.customizeColumns"
      :data-source="treeListConfig.dataSource"
      :data-structure="treeListConfig.dataStructure"
      :expanded-row-keys="treeListConfig.expandedRowKeys"
      :expand-nodes-on-filtering="treeListConfig.expandNodesOnFiltering"
      :filter-builder="treeListConfig.filterBuilder"
      :filter-builder-popup="treeListConfig.filterBuilderPopup"
      :filter-mode="treeListConfig.filterMode"
      :filter-value="treeListConfig.filterValue"
      :focused-column-index="treeListConfig.focusedColumnIndex"
      :focused-row-enabled="treeListConfig.focusedRowEnabled"
      :focused-row-index="treeListConfig.focusedRowIndex"
      :focused-row-key="treeListConfig.focusedRowKey"
      :has-items-expr="treeListConfig.hasItemsExpr"
      :highlight-changes="treeListConfig.highlightChanges"
      :hint="treeListConfig.hint"
      :hover-state-enabled="treeListConfig.hoverStateEnabled"
      :items-expr="treeListConfig.itemsExpr"
      :key-expr="treeListConfig.keyExpr"
      :no-data-text="noDataText()"
      :parent-id-expr="treeListConfig.parentIdExpr"
      :render-async="treeListConfig.renderAsync"
      :repaint-changes-only="treeListConfig.repaintChangesOnly"
      :root-value="treeListConfig.rootValue"
      :row-alternation-enabled="treeListConfig.rowAlternationEnabled"
      :rtl-enabled="treeListConfig.rtlEnabled"
      :selected-row-keys="treeListConfig.selectedRowKeys"
      :show-borders="treeListConfig.showBorders"
      :show-column-headers="treeListConfig.showColumnHeaders"
      :show-column-lines="treeListConfig.showColumnLines"
      :show-row-lines="treeListConfig.showRowLines"
      :sync-lookup-filter-values="treeListConfig.syncLookupFilterValues"
      :tab-index="treeListConfig.tabIndex"
      :two-way-binding-enabled="treeListConfig.twoWayBindingEnabled"
      :word-wrap-enabled="treeListConfig.wordWrapEnabled"
      @adaptive-detail-row-preparing="handleAdaptiveDetailRowPreparing"
      @cell-click="handleCellClick"
      @cell-dbl-click="handleCellDblClick"
      @cell-hover-changed="handleCellHoverChanged"
      @cell-prepared="handleCellPrepared"
      @content-ready="handleContentReady"
      @context-menu-preparing="handleContextMenuPreparing"
      @data-error-occurred="handleDataErrorOccurred"
      @disposing="handleDisposing"
      @edit-canceled="handleEditCanceled"
      @edit-canceling="handleEditCanceling"
      @editing-start="handleEditingStart"
      @editor-prepared="handleEditorPrepared"
      @editor-preparing="handleEditorPreparing"
      @focused-cell-changed="handleFocusedCellChanged"
      @focused-cell-changing="handleFocusedCellChanging"
      @focused-row-changed="handleFocusedRowChanged"
      @focused-row-changing="handleFocusedRowChanging"
      @initialized="handleInitialized"
      @init-new-row="handleInitNewRow"
      @key-down="handleKeyDown"
      @nodes-initialized="handleNodesInitialized"
      @option-changed="handleOptionChanged"
      @row-click="handleRowClick"
      @row-collapsed="handleRowCollapsed"
      @row-collapsing="handleRowCollapsing"
      @row-dbl-click="handleRowDblClick"
      @row-expanded="handleRowExpanded"
      @row-expanding="handleRowExpanding"
      @row-inserted="handleRowInserted"
      @row-inserting="handleRowInserting"
      @row-prepared="handleRowPrepared"
      @row-removed="handleRowRemoved"
      @row-removing="handleRowRemoving"
      @row-updated="handleRowUpdated"
      @row-updating="handleRowUpdating"
      @row-validating="handleRowValidating"
      @saved="handleSaved"
      @saving="handleSaving"
      @selection-changed="handleSelectionChanged"
      @toolbar-preparing="handleToolbarPreparing"
    >
      <!-- 항목 출력 여부 관련 설정 -->
      <DxColumnChooser
        v-if="treeListConfig.columnChooser"
        :enabled="treeListConfig.columnChooser.enabled"
      />

      <!-- 컬럼 고정 설정 -->
      <DxColumnFixing
        v-if="treeListConfig.columnFixing"
        :enabled="treeListConfig.columnFixing.enabled"
      />

      <!-- 수정모드 -->
      <DxEditing
        :allow-adding="treeListConfig.editing.allowAdding"
        :allow-updating="treeListConfig.editing.allowUpdating"
        :allow-deleting="treeListConfig.editing.allowDeleting"
        :confirm-delete="treeListConfig.editing.confirmDelete"
        :edit-column-name="treeListConfig.editing.editColumnName"
        :edit-row-key="treeListConfig.editing.editRowKey"
        :mode="treeListConfig.editing.mode"
        :refresh-mode="treeListConfig.editing.refreshMode"
        :select-text-on-edit-start="treeListConfig.editing.selectTextOnEditStart"
        :start-edit-action="treeListConfig.editing.startEditAction"
        :texts="treeListConfig.editing.texts"
        :use-icons="treeListConfig.editing.useIcons"
      />

      <!-- 필터 설정 -->
      <DxFilterRow
        :v-if="treeListConfig.filterRow"
        :visible="treeListConfig.filterRow.visible"
      />

      <!-- 헤더필터 설정 -->
      <DxHeaderFilter
        :v-if="treeListConfig.headerFilter"
        :visible="treeListConfig.headerFilter.visible"
      />

      <!-- 키보드 네비게이션 설정 -->
      <DxKeyboardNavigation
        :v-if="treeListConfig.keyboardNavigation"
        :enabled="treeListConfig.keyboardNavigation.enabled"
        :edit-on-key-press="treeListConfig.keyboardNavigation.editOnKeyPress"
        :enter-key-action="treeListConfig.keyboardNavigation.enterKeyAction"
        :enter-key-direction="treeListConfig.keyboardNavigation.enterKeyDirection"
      />

      <!-- 로딩바 표시 유무 설정 -->
      <DxLoadPanel
        v-if="treeListConfig.loadPanel"
        :enabled="treeListConfig.loadPanel.enabled"
      />

      <!-- 서버사이드 설정 -->
      <DxRemoteOperations
        :filtering="treeListConfig.remoteOperations.filtering"
        :sorting="treeListConfig.remoteOperations.sorting"
        :grouping="treeListConfig.remoteOperations.grouping"
        :paging="treeListConfig.remoteOperations.paging"
      />

      <!-- 행 드래그 관련 -->
      <DxRowDragging
        v-if="treeListConfig.rowDragging"
        :allow-drop-inside-item="treeListConfig.rowDragging.enabled"
        :allow-reordering="treeListConfig.rowDragging.allowReordering"
        :show-drag-icons="treeListConfig.rowDragging.showDragIcons"
        :on-drag-change="handleDragChangeRow"
        :on-reorder="handleReorderRow"
      />

      <!-- 스크롤 설정 -->
      <DxScrolling
        v-if="treeListConfig.scrolling"
        :column-rendering-mode="treeListConfig.scrolling.columnRenderingMode"
        :mode="treeListConfig.scrolling.mode"
        :preload-enabled="treeListConfig.scrolling.preloadEnabled"
        :render-async="treeListConfig.scrolling.renderAsync"
        :row-rendering-mode="treeListConfig.scrolling.rowRenderingMode"
        :scroll-by-content="treeListConfig.scrolling.scrollByContent"
        :scroll-by-thumb="treeListConfig.scrolling.scrollByThumb"
        :show-scrollbar="treeListConfig.scrolling.showScrollbar"
        :use-native="treeListConfig.scrolling.useNative"
      />

      <!-- 검색 패널 설정 -->
      <DxSearchPanel
        v-if="treeListConfig.searchPanel"
        :visible="treeListConfig.searchPanel.visible"
        :highlightCaseSensitive="treeListConfig.searchPanel.highlightCaseSensitive"
        :highlightSearchText="treeListConfig.searchPanel.highlightSearchText"
        :searchVisibleColumnsOnly="treeListConfig.searchPanel.searchVisibleColumnsOnly"
        :text="treeListConfig.searchPanel.text"
        :placeholder="treeListConfig.searchPanel.placeholder"
        :width="treeListConfig.searchPanel.width"
      />

      <!-- 로우 선택 설정 -->
      <DxSelection
        v-if="treeListConfig.selection"
        :allow-select-all="treeListConfig.selection.allowSelectAll"
        :mode="treeListConfig.selection.mode"
        :recursive="treeListConfig.selection.recursive"
      />

      <!-- 순서 설정 -->
      <DxSorting
        v-if="treeListConfig.sorting"
        :mode="treeListConfig.sorting.mode"
        :show-sort-indexes="treeListConfig.sorting.showSortIndexes"
      />

      <!-- 항목 설정 -->
      <template v-for="(column, index) in treeListConfig.columns">
        <template v-if="column.multiHeaderNm">
          <DxColumn :key="index" :caption="$_msgContents(column.i18n, { defaultValue: column.multiHeaderNm })">
            <DxColumn
              v-for="(column2, index2) in column.columns"
              :key="`${index}_${index2}`"
              :alignment="column2.alignment"
              :allow-editing="column2.allowEditing"
              :allow-filtering="column2.allowFiltering"
              :allow-fixing="column2.allowFixing"
              :allow-header-filtering="column2.allowHeaderFiltering"
              :allow-hiding="column2.allowHiding"
              :allow-reordering="column2.allowReordering"
              :allow-resizing="column2.allowResizing"
              :allow-search="column2.allowSearch"
              :allow-sorting="column2.allowSorting"
              :calculate-cell-value="column2.calculateCellValue"
              :calculate-display-value="column2.calculateDisplayValue"
              :calculate-filter-expression="column2.calculateFilterExpression"
              :calculate-sort-value="column2.calculateSortValue"
              :caption="$_msgContents(column2.i18n, { defaultValue: column2.caption })"
              :cell-template="column2.cellTemplate"
              :columns="column2.columns"
              :css-class="column2.cssClass"
              :customize-text="column2.customizeText"
              :data-field="column2.dataField"
              :data-type="column2.dataType"
              :edit-cell-template="column2.editCellTemplate"
              :editor-options="column2.editorOptions"
              :encode-html="column2.encodeHtml"
              :false-text="column2.falseText"
              :filter-operations="column2.filterOperations"
              :filter-type="column2.filterType"
              :filterValue="column2.filterValue"
              :filterValues="column2.filterValues"
              :fixed="column2.fixed"
              :fixed-position="column2.fixedPosition"
              :format="column2.format"
              :format-item="column2.formatItem"
              :header-cell-template="column2.headerCellTemplate"
              :hiding-priority="column2.hidingPriority"
              :is-band="column2.isBand"
              :min-width="column2.minWidth"
              :name="column2.name"
              :owner-band="column2.ownerBand"
              :render-async="column2.renderAsync"
              :selected-filter-operation="column2.selectedFilterOperation"
              :set-cell-value="column2.setCellValue"
              :show-editor-always="column2.showEditorAlways"
              :show-in-column-chooser="column2.showInColumnChooser"
              :sort-index="column2.sortIndex"
              :sorting-method="column2.sortingMethod"
              :sort-order="column2.sortOrder"
              :true-text="column2.trueText"
              :type="column2.type"
              :validation-rules="column2.validationRules"
              :visible="column2.visible"
              :visible-index="column2.visibleIndex"
              :width="column2.width"
            >
              <!-- 헤더필터 설정 -->
              <DxHeaderFilter
                v-if="column2.headerFilter"
                :data-source="column2.headerFilter.dataSource"
              />

              <!-- selectBox 옵션 -->
              <DxLookup
                v-if="column2.lookup"
                :allow-clearing="column2.lookup.allowClearing"
                :data-source="column2.lookup.dataSource"
                :display-expr="column2.lookup.displayExpr"
                :value-expr="column2.lookup.valueExpr"
              />

              <!-- 필수조건 설정 -->
              <DxRequiredRule
                v-if="column2.requiredRule"
                :message="$_msgContents(column2.requiredRule.i18n, { defaultValue: column2.requiredRule.message })"
              />

              <!-- 패턴 규칙 설정 -->
              <DxPatternRule
                v-if="column2.patternRule"
                :pattern="column2.patternRule.pattern"
                :message="column2.patternRule.message"
              />

              <!-- 커스텀 규칙 설정 -->
              <DxCustomRule
                v-if="column2.customRule"
                type="custom"
                :validationCallback="column2.customRule.callback"
                :message="column2.customRule.message"
              />
            </DxColumn>
          </DxColumn>
        </template>
        <template v-else>
          <DxColumn
            :key="index"
            :alignment="column.alignment"
            :allow-editing="column.allowEditing"
            :allow-filtering="column.allowFiltering"
            :allow-fixing="column.allowFixing"
            :allow-header-filtering="column.allowHeaderFiltering"
            :allow-hiding="column.allowHiding"
            :allow-reordering="column.allowReordering"
            :allow-resizing="column.allowResizing"
            :allow-search="column.allowSearch"
            :allow-sorting="column.allowSorting"
            :calculate-cell-value="column.calculateCellValue"
            :calculate-display-value="column.calculateDisplayValue"
            :calculate-filter-expression="column.calculateFilterExpression"
            :calculate-sort-value="column.calculateSortValue"
            :caption="$_msgContents(column.i18n, { defaultValue: column.caption })"
            :cell-template="column.cellTemplate"
            :columns="column.columns"
            :css-class="column.cssClass"
            :customize-text="column.customizeText"
            :data-field="column.dataField"
            :data-type="column.dataType"
            :edit-cell-template="column.editCellTemplate"
            :editor-options="column.editorOptions"
            :encode-html="column.encodeHtml"
            :false-text="column.falseText"
            :filter-operations="column.filterOperations"
            :filter-type="column.filterType"
            :filterValue="column.filterValue"
            :filterValues="column.filterValues"
            :fixed="column.fixed"
            :fixed-position="column.fixedPosition"
            :format="column.format"
            :format-item="column.formatItem"
            :header-cell-template="column.headerCellTemplate"
            :hiding-priority="column.hidingPriority"
            :is-band="column.isBand"
            :min-width="column.minWidth"
            :name="column.name"
            :owner-band="column.ownerBand"
            :render-async="column.renderAsync"
            :selected-filter-operation="column.selectedFilterOperation"
            :set-cell-value="column.setCellValue"
            :show-editor-always="column.showEditorAlways"
            :show-in-column-chooser="column.showInColumnChooser"
            :sort-index="column.sortIndex"
            :sorting-method="column.sortingMethod"
            :sort-order="column.sortOrder"
            :true-text="column.trueText"
            :type="column.type"
            :validation-rules="column.validationRules"
            :visible="column.visible"
            :visible-index="column.visibleIndex"
            :width="column.width"
          >
            <!-- 헤더필터 설정 -->
            <DxHeaderFilter
              v-if="column.headerFilter"
              :data-source="column.headerFilter.dataSource"
            />

            <!-- selectBox 옵션 -->
            <DxLookup
              v-if="column.lookup"
              :data-source="column.lookup.dataSource"
              :display-expr="column.lookup.displayExpr"
              :value-expr="column.lookup.valueExpr"
              :allow-clearing="column.lookup.allowClearing"
            />

            <!-- 필수조건 설정 -->
            <DxRequiredRule
              v-if="column.requiredRule"
              :message="column.requiredRule.message"
            />

            <!-- 패턴 규칙 설정 -->
            <DxPatternRule
              v-if="column.patternRule"
              :pattern="column.patternRule.pattern"
              :message="column.patternRule.message"
            />

            <!-- 커스텀 규칙 설정 -->
            <DxCustomRule
              v-if="column.customRule"
              type="custom"
              :validationCallback="column.customRule.callback"
              :message="column.customRule.message"
            />
          </DxColumn>
        </template>
      </template>
      <template #removeTemplate="{ data }">
        <div>
          <DxButton text="" template="<span class='mdi mdi-trash-can'></span>" @click="handleDeleteData(data)"/>
        </div>
      </template>
    </DxTreeList>
  </div>
</template>

<script>
import {DxCheckBox} from "devextreme-vue/check-box";
import {DxButton} from "devextreme-vue/button";
import {isSuccess} from '@/plugins/common-lib';
import {
  DxTreeList,
  DxColumn,
  DxColumnChooser,
  DxColumnFixing,
  DxEditing,
  DxFilterRow,
  DxSearchPanel,
  DxLookup,
  DxHeaderFilter,
  DxKeyboardNavigation,
  DxRequiredRule,
  DxCustomRule,
  DxSelection,
  DxRowDragging,
  DxScrolling
} from "devextreme-vue/tree-list";
import {DxPatternRule} from 'devextreme-vue/validator';
import store from '@/store';
import {DxLoadPanel, DxRemoteOperations, DxSorting} from "devextreme-vue/data-grid";
//import {DxColumnChooser, DxDataGrid} from "devextreme-vue/data-grid";
import { cloneObj } from '@/plugins/common-lib';

export default {
  components: {
    DxSorting,
    DxRemoteOperations,
    DxLoadPanel,
    DxTreeList,
    DxColumnChooser,
    DxColumnFixing,
    DxFilterRow,
    DxSearchPanel,
    DxEditing,
    DxCheckBox,
    DxColumn,
    DxButton,
    DxLookup,
    DxHeaderFilter,
    DxKeyboardNavigation,
    DxRequiredRule,
    DxPatternRule,
    DxCustomRule,
    DxSelection,
    DxRowDragging,
    DxScrolling
  },
  props: {
    treeList: {
      type: Object,
      required: true,
    },
  },
  watch: {
    treeList: {
      handler(newTreeList) {
        this.setTreeListData(newTreeList); // 트리 리스트 데이터 설정
      },
      immediate: true,
      deep: false,
    }, // treeList 변경감지(렌더링 직후 바로 실행, 내부 객체 변경은 감지하지 않음)
    'treeList.dataSource': {
      handler(newDataSource) {
        this.treeListConfig.dataSource = newDataSource;
      },
      deep: true,
    }, // treeList.dataSource 변경감지(내부 객체 변경까지 감지)
  },
  data() {
    return {
      treeListConfig: {
        checkedViewFl: false, // 사용중인 항목 체크박스 여부
        isReordered: false, // 드래그로 순서 변경 여부
      }, // 트리 리스트 설정값
      treeDefaultOptions: { // 트리 디폴트 옵션값 설정을 위해 관리
        callApi: this.treeList.callApi ?? 'CALL_API',
        allowColumnReordering: false, // 컬럼 재배열 허용
        allowColumnResizing: false, // 컬럼 크기 조정 허용
        autoExpandAll: true, // 모든 노드 확장
        autoNavigateToFocusedRow: true, // 포커스된 행으로 자동 이동
        cacheEnabled: true, // 캐시 사용
        cellHintEnabled: true, // 셀 힌트 사용
        columnAutoWidth: false, // 컬럼 자동 너비
        columnHidingEnabled: false, // 컬럼 숨기기 허용
        columnMinWidth: 50, // 컬럼 최소 너비
        columnResizingMode: 'nextColumn', // 컬럼 크기 조정 모드(nextColumn: 전체 너비 영향없이 옆 컬럼의 너비가 조정, widget: 다른 컬럼들의 너비 영향없이 전체 너비 조정)
        customizeColumns: undefined, // 컬럼 사용자 정의
        dataSource: [], // 트리 데이터
        dataSourceDefaultSortColumn: '+id', // 해당 컬럼으로 정렬: + 오름차순 / - 내림차순
        dataStructure: 'plain', // 데이터 구조(plain: 평면, tree: 트리)
        expandedRowKeys: [], // 확장된 행 키
        expandNodesOnFiltering: true, // 필터링 시 노드 확장
        filterBuilder: {}, // 필터 빌더
        filterBuilderPopup: {}, // 필터 빌더 팝업
        filterMode: 'fullBranch', // 필터 모드(fullBranch: 전체 표시, withAncestors: 일치하는 항목과 상위 항목 표시, matchOnly: 일치하는 항목만 표시)
        filterSyncEnabled: 'auto', // 필터 동기화 활성화/리스트와 FilterBuilder 간의 동기화 여부(auto: 자동, true: 활성화, false: 비활성화)
        filterValue: undefined, // 필터 값
        focusedColumnIndex: -1, // 포커스된 컬럼 인덱스
        focusedRowEnabled: true, // 포커스된 행 활성화
        focusedRowIndex: -1, // 포커스된 행 인덱스
        focusedRowKey: null, // 포커스된 행 키
        hasItemsExpr: undefined, // 하위 항목이 있는지 여부를 나타내는 옵션
        highlightChanges: false, // 데이터가 변경될 때 변경된 셀 하이라이팅
        hint: '', // 힌트
        hoverStateEnabled: false, // 호버 상태 활성화
        itemsExpr: 'items', // 하위 항목을 나타내는 필드
        keyExpr: 'id', // 키 필드
        parentIdExpr: 'parentId', // 부모 키 필드
        renderAsync: false, // 비동기 렌더링(ture: 비동기, false: 동기)
        repaintChangesOnly: false, // 변경 사항만 다시 그리기
        rootValue: -1, // 루트 값
        rowAlternationEnabled: false, // 행 배경색 번갈아 표시
        rtlEnabled: false, // RTL(Right To Left) 활성화 여부
        selectedRowKeys: [], // 선택된 행 키
        showBorders: false, // 테두리 표시
        showColumnHeaders: true, // 컬럼 헤더 표시
        showColumnLines: false, // 컬럼 선 표시
        showRowLines: true, // 행 선 표시
        syncLookupFilterValues: true, // 룩업 필터 값 동기화 여부
        tabIndex: 0, // 탭 인덱스
        twoWayBindingEnabled: true, // 양방향 바인딩 활성화
        wordWrapEnabled: false, // 텍스트 줄바꿈 활성화 여부
        apiActionNm: { // api 호출시 사용할 action name
          select: null, // 조회
          merge: null, // 등록/수정
          delete: null, // 삭제
        },
        showActionButtons: { // 그리드 버튼 노출 설정값
          update: true, // 추가/저장/취소
          delete: false, // 삭제
          sort: true, // 순서 저장
          toggleExpand: true, // 목록 펼치기/접기
          customButtons: [], // 커스텀 버튼
        },
        filterUseItem: { // 사용중인 항목만 보기 설정 값
          enabled: false, // 사용중인 항목만 보기 / false가 기본
          key: 'viewFl', //사용중인 항목만 보기 컬럼
        },
        columnChooser: { // 컬럼 Chooser 설정
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        columnFixing: { // 컬럼 고정 설정
          enabled: false, // 컬럼 고정 설정 사용유무
        },
        editing: { // 수정모드
          allowAdding: true, // 상단 추가버튼 / 컬럼 하위추가버튼 표시 여부
          allowUpdating: true,
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch', 'form', 'popup']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          refreshMode: 'full', //수정 후 데이터 갱신 모드 : ['full', 'reshape', 'repaint']
          newRowPosition: 'viewportTop', // 새로운 행 추가 위치 : ['first', 'last', 'pageTop', 'pageBottom', 'viewportTop', 'viewportBottom']
          confirmDelete: true, //삭제시 확인 메시지 표시 여부
          editColumnName: null, // 편집중인 열 이름 / ['cell', 'batch'] 모드인 경우에만 가능
          editRowKey: null, // 편집중인 행 키값
          useIcons: true, // 아이콘 사용 여부
          texts: null, // 텍스트 설정
        },
        filterRow: { //필터 설정
          visible: true,
        },
        headerFilter: { //헤더필터 설정
          visible: false,
        },
        keyboardNavigation: { // 키보드 네비게이션 설정
          enabled: false, // 키보드 네비게이션 사용 여부
          editOnKeyPress: false, // 키 입력시 편집 모드 진입
          enterKeyAction: 'startEdit', // 엔터키 액션 : ['startEdit', 'moveFocus']
          enterKeyDirection: 'none', // 엔터키 방향 : ['none', 'column', 'row']
        },
        loadPanel: { // 로딩바 표시 유무 설정
          enabled: true, // 로딩바 표시 여부
        },
        remoteOperations: { //서버사이드 설정
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        rowDragging: {
          enabled: false,
          allowDropInsideItem: false, // 드래그로 아이템 안에 드롭 허용 여부
          allowReordering: true, // 드래그로 순서 변경 허용 여부
          showDragIcons: true // 드래그 아이콘 표시 여부
        },
        scrolling: { // 스크롤 설정
          columnRenderingMode: 'standard', // 컬럼 렌더링 모드 : ['standard', 'virtual']
          mode: 'standard', // 스크롤 모드 : ['standard', 'virtual', 'infinite']
          preloadEnabled: false, // 미리 로딩 여부 / 모드 ['virtual', 'infinite'] 일때만 사용
          renderAsync: undefined, // 비동기 렌더링 여부 [true, false, undefined]
          rowRenderingMode: 'standard', // 행 렌더링 모드 : ['standard', 'virtual']
          scrollByContent: true, // 컨텐츠 스크롤 여부
          scrollByThumb: false, // 스크롤바 스크롤 여부
          showScrollbar: 'onHover', // 스크롤바 표시 여부 : ['onHover', 'onScroll', 'always', 'never']
          useNative: false, // 네이티브 스크롤 사용 여부 ['auto', 'true', 'false']
        },
        searchPanel: { // 검색 패널 설정
          visible: false, // 검색 패널 표시 여부
          highlightCaseSensitive: true, // 대소문자 구분 하이라이트 유무
          highlightSearchText: true, // 검색어 하이라이트 표시 유무
          searchVisibleColumnsOnly: true, // 검색 대상 컬럼만 검색 유무
          text: '', // 검색 입력박스 텍스트
          placeholder: '', // 검색 입력박스 placeholder
          width: 200, // 검색 입력박스 넓이
        },
        selection: { //로우 선택 설정
          allowSelectAll: true, //헤더 체크박스 선택(전체선택) 허용 여부
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple', 'none']
          recursive: true, //상위 노드 선택시 하위 노드도 선택 여부(true: 하위 노드도 선택, false: 하위 노드 선택 안하고 독립적)
        },
        sorting: { // 정렬 설정
          mode: 'multiple', // ['single', 'multiple', 'none']
          showSortIndexes: true, // 정렬 순서 표시 유무
        },
      },
      columnDefaultOptions: { // column 기본 옵션
        alignment: 'center', // 컬럼 정렬 : ['center', 'left', 'right']
        allowEditing: true, // 수정 허용 여부
        allowFiltering: true, // 검색 필터 허용 여부
        allowFixing: true, // 고정 허용 여부
        allowHeaderFiltering: true, // 헤더 필터 허용 여부
        allowHiding: true, // 컬럼 숨김 허용 여부
        allowReordering: true, // 컬럼 재배열 허용 여부
        allowResizing: true, // 컬럼 사이즈 조절 허용 여부
        allowSearch: true, // 검색 허용 여부(searchPanel 설정시 사용 가능 / false로 설정시 allowFiltering도 false로 설정)
        allowSorting: true, // 정렬 허용 여부
        autoExpandGroup: true, // 그룹 확장 여부
        fixed: false, // 고정 컬럼 여부
        fixedPosition: undefined, // 고정 컬럼 위치 : [undefined, 'left', 'right']
        minWidth: 50, // 컬럼 최소 넓이
        sortOrder: 'none', // 정렬 순서 : ['none', 'asc', 'desc']
        visible: true, // 컬럼 표시 여부
      },
      stylingMode: 'outlined', //outlined, underlined, filled
    };
  },
  computed: {},
  methods: {
    /** @description: 트리 리스트 데이터 설정
     *  @param treeList : 트리 리스트 데이터
     * */
    setTreeListData(treeList) {
      // 트리 안에 객체 데이터 확인하여 병합 처리(ex. paging, pager, ...)
      let mergeTreeOptions = {};
      Object.keys(this.treeDefaultOptions).forEach((key) => {
        if( Array.isArray(this.treeDefaultOptions[key]) ){  //배열 체크
          mergeTreeOptions = { ...mergeTreeOptions, ...this.mergeTreeData(key, 'array') };
        }else if( typeof this.treeDefaultOptions[key] === 'object' ) { //Object 체크
          mergeTreeOptions = { ...mergeTreeOptions, ...this.mergeTreeData(key, 'object') };
        }
      });

      //부모에서 전달된 dataGrid 기본값과 병합
      this.treeListConfig = {
        ...this.treeDefaultOptions,
        ...treeList,
        ...mergeTreeOptions,
      };

      if ( this.treeListConfig.showActionButtons?.delete ) { // 삭제 버튼 사용시
        this.setMultipleSelection(); // 멀티 선택 모드 설정
        this.setDeleteColumn(); // 삭제 컬럼 설정
      }

      // 컬럼 기본 옵션 설정
      this.treeListConfig.columns = this.setColumnOptions();

      // treeList visible 속성을 가진 기본옵션 객체가 존재하면 해당 속성 활성화
      this.setActiveAttr(treeList, 'visible');

      // treeList enabled 속성을 가진 기본옵션 객체가 존재하면 해당 속성 활성화
      this.setActiveAttr(treeList, 'enabled');
    },
    /** @description : 트리 데이터 병합
     *  @param objectKey : 병합할 데이터의 키값
     *  @param dataType : 병합할 데이터의 타입(array, object)
     *  @return jsonData : 병합된 데이터
     *  */
    mergeTreeData(objectKey, dataType) {
      let mergedData = {}
      if( dataType === 'array' ) {
        mergedData = [
          ...(Array.isArray(this.treeDefaultOptions[objectKey]) ? this.treeDefaultOptions[objectKey] : []),
          ...(Array.isArray(this.treeList[objectKey]) ? this.treeList[objectKey] : []),
        ];
      } else if( dataType === 'object' ){
        mergedData = {
          [objectKey]: this.deepMergeObj(
            { ...(this.treeDefaultOptions[objectKey] || {}) },
            { ...(this.treeList[objectKey] || {}) },
          )
        };
      }
      return mergedData;
    },
    /** @description : 중첩된 객체 병합
     *  @param target : 병합할 대상 객체
     *  @param source : 병합할 소스 객체
     *  @return target : 병합된 객체
     *  */
    deepMergeObj(target, source) {
      // 객체 체크
      const isObject = obj => obj && typeof obj === 'object' && !Array.isArray(obj);

      Object.keys(source).forEach(key => {
        const targetValue = target[key];
        const sourceValue = source[key];

        if (isObject(sourceValue)) { // 객체가 중첩된 경우 재귀적으로 병합
          if (!targetValue || !isObject(targetValue)) {
            target[key] = {}; // target 값이 없으면 빈 객체로 초기화
          }
          target[key] = this.deepMergeObj({ ...targetValue }, sourceValue);
        } else { // 객체가 아니면 그대로 덮어씀
          target[key] = sourceValue;
        }
      });

      return target;
    },
    /** @description: 트리 리스트 선택 멀티모드 설정 */
    setMultipleSelection() {
      this.treeListConfig.selection.mode = 'multiple';
    },
    /** @description: 트리 리스트 삭제 컬럼 설정 */
    setDeleteColumn() {
      const deleteColumn = {
        caption: this.$_msgContents('COMPONENTS.DELETE', {defaultValue: '삭제'}),
        cellTemplate: 'removeTemplate',
        dataField: 'id',
        allowHeaderFiltering: false,
        allowFiltering: false,
        width: 100,
      }
      this.treeListConfig.columns = [ ...this.treeListConfig.columns, deleteColumn ];
    },
    /** @description: 트리 컬럼 옵션 설정
     *  @return {Array} - 컬럼 옵션 설정값
     * */
    setColumnOptions() {
      return this.treeListConfig.columns.map(column => {
        if( column.requiredRule ){
          const requiredRule = { //필수 값 메시지 처리
            message : this.$_msgContents('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: this.$_msgContents(column.i18n, {defaultValue: column.caption})})
          };
          column.requiredRule = { ...column.requiredRule, ...requiredRule };
        }
        return { ...this.columnDefaultOptions, ...column };
      });
    },
    /** @description: 객체가 존재하면서 해당 속성에 false가 아니면 true를 설정(해당 속성 활성화)
     *                해당 객체가 존재하면 visible, enabled 속성에 true 설정
     *                filterRow: {} && filterRow.visible != false
     *  @param obj - 설정할 객체
     *  @param attr - 설정할 속성
     *
     * */
    setActiveAttr(obj, attr) {
      // 트리 기본 옵션에 있는 특정 속성이 있는 키 값 조회
      const keysByAttr = this.getKeysByAttr(this.treeDefaultOptions, attr);

      Object.keys(obj).forEach(key => {
        if( keysByAttr.includes(key) && this.treeList[key]?.[attr] !== false ){ // 객체가 존재하면서 해당 속성에 false가 아니면 true를 설정
          this.treeListConfig[key][attr] = true;
        }
      });
    },
    /** @description: 특정 속성이 있는 key 값을 조회
     *                visible, enabled 속성이 있는 key 값 리턴
     *  @param obj - 조회할 객체
     *  @param attr - 조회할 속성
     *  @return {Array} - 조회된 key 값
     * */
    getKeysByAttr(obj, attr) {
      return Object.keys(obj).filter(key =>
        obj[key] && typeof obj[key] === 'object' && attr in obj[key]
      );
    },
    /** @description: 적응형 세부 행이 준비될 때 발생하는 이벤트  */
    handleAdaptiveDetailRowPreparing(e) {
      if (this.$listeners['adaptive-detail-row-preparing']) {
        this.$emit('adaptive-detail-row-preparing', e);
      }
    },
    /** @description: 셀 클릭 이벤트 */
    handleCellClick(e) {
      if (this.$listeners['cell-click']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('cell-click', e);
      }
    },
    /** @description: 셀 더블 클릭 이벤트 */
    handleCellDblClick(e) {
      if (this.$listeners['cell-dbl-click']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('cell-dbl-click', e);
      }
    },
    /** @description: 마우스 포인터가 셀에 들어오거나 나가는 후에 실행되는 이벤트  */
    handleCellHoverChanged(e) {
      if (this.$listeners['cell-hover-changed']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('cell-hover-changed', e);
      }
    },
    /** @description: 그리드 셀 관련 준비 이벤트 */
    handleCellPrepared(e) {
      if (this.$listeners['cell-prepared']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('cell-prepared', e);
      }
    },
    /** @description: 그리드 컨텐츠 준비 이벤트 */
    handleContentReady(e) {
      if (this.$listeners['content-ready']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('content-ready', e);
      }
    },
    /** @description: 그리드 컨텍스트메뉴 준비 관련 이벤트 */
    handleContextMenuPreparing(e) {
      if (this.$listeners['context-menu-preparing']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('context-menu-preparing', e);
      }
    },
    /** @description: 데이터 처리 오류가 발생하면 실행되는 이벤트 */
    handleDataErrorOccurred(e) {
      if ( this.$listeners['data-error-occurred'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('data-error-occurred', e);
      }
    },
    /** @description: 컴포넌트가 소멸될 때 실행되는 이벤트(소멸 전 정리 작업 수행) */
    handleDisposing(e) {
      if ( this.$listeners['disposing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('disposing', e);
      }
    },
    /** @description: 편집이 완전히 취소된 후 발생하는 이벤트 */
    handleEditCanceled(e) {
      if ( this.$listeners['edit-canceled'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('edit-canceled', e);
      }
    },
    /** @description: 편집 취소가 시작되기 직전에 발생하는 이벤트 */
    handleEditCanceling(e) {
      if ( this.$listeners['edit-canceling'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('edit-canceling', e);
      }
    },
    /** @description: 편집이 시작되기 직전에 발생하는 이벤트 */
    handleEditingStart(e) {
      if ( this.$listeners['editing-start'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('editing-start', e);
      }
    },
    /** @description: 편집기가 생성된 후 실행되는 함수 */
    handleEditorPrepared(e) {
      if (this.$listeners['editor-prepared']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('editor-prepared', e);
      }
    },
    /** @description: 셀이 변경 준비중일때 실행되는 이벤트 */
    handleEditorPreparing(e) {
      if (this.$listeners['editor-preparing']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('editor-preparing', e);
      }
    },
    /** @description: 셀 포커스가 이미 변경된 후에 발생하는 이벤트(포커스 이후 작업 수행) */
    handleFocusedCellChanged(e) {
      if ( this.$listeners['focused-cell-changed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-cell-changed', e);
      }
    },
    /** @description: 셀 포커스가 변경되기 직전에 발생하는 이벤트(포커스 이동을 막을 수 있음) */
    handleFocusedCellChanging(e) {
      if ( this.$listeners['focused-cell-changing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-cell-changing', e);
      }
    },
    /** @description: 포커스된 행이 변경된 후 실행되는 함수 */
    handleFocusedRowChanged(e) {
      if (this.$listeners['focused-row-changed']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-row-changed', e);
      }
    },
    /** @description: 포커스된 행이 변경되기 전에 실행되는 함수 */
    handleFocusedRowChanging(e) {
      if (this.$listeners['focused-row-changing']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('focused-row-changing', e);
      }
    },
    /** @description: 그리드 초기화 이벤트 */
    handleInitialized(e) {
      if (this.$listeners['initialized']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('initialized', e);
      }
    },
    /** @description: 그리드 새 행 초기 셋팅 이벤트
     * ex) 필드의 순서 값 등 초기 셋팅 */
    handleInitNewRow(e) {
      if (this.$listeners['init-new-row']) {
        this.$emit('init-new-row', e);
      } else {
        let childrenNode = this.$refs[this.treeListConfig.refName].instance.getNodeByKey(e.data.parentId).children;
        e.data[this.treeListConfig.sortKey] = 0;
        if (e.data.parentId !== -1 && childrenNode.length !== 0) {
          e.data[this.treeListConfig.key] = childrenNode[0].data[this.treeListConfig.key];
          e.data[this.treeListConfig.sortKey] = childrenNode[childrenNode.length - 1].data[this.treeListConfig.sortKey] + 1;
          e.data.depth = childrenNode[0].data.depth;
        } else {
          e.data.depth = 1;
        }
      }
    },
    /** @description: 키보드 키가 눌릴 때 트리거되는 이벤트 */
    handleKeyDown(e) {
      if (this.$listeners['key-down']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('key-down', e);
      }
    },
    /** @description: 노드가 초기화될 때 실행되는 이벤트 */
    handleNodesInitialized(e) {
      if (this.$listeners['nodes-initialized']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('nodes-initialized', e);
      }
    },
    /** @description: 옵션 변경시 이벤트 발생(단, 초기 인입시에도 호출됨)
     * @param e : 이벤트
     * */
    handleOptionChanged(e) {
      if (this.$listeners['option-changed']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('option-changed', e);
      }
    },
    /** @description: 로우 클릭시 이벤트 */
    handleRowClick(e) {
      if (this.$listeners['row-click']) { //부모 컴포넌트의 이벤트 호출
        this.$emit('row-click', e);
      }
    },
    /** @description: 행이 완전히 접힌 후 발생하는 이벤트 */
    handleRowCollapsed(e) {
      if ( this.$listeners['row-collapsed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-collapsed', e);
      }
    },
    /** @description: 행이 접히기 직전에 발생하는 이벤트 */
    handleRowCollapsing(e) {
      if ( this.$listeners['row-collapsing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-collapsing', e);
      }
    },
    /** @description: 행 더블 클릭 이벤트 */
    handleRowDblClick(e) {
      if ( this.$listeners['row-dbl-click'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-dbl-click', e);
      }
    },
    /** @description: 행이 완전히 펼쳐진 후 발생하는 이벤트 */
    handleRowExpanded(e) {
      if ( this.$listeners['row-expanded'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-expanded', e);
      }
    },
    /** @description: 행이 펼쳐지기 직전에 발생하는 이벤트 */
    handleRowExpanding(e) {
      if ( this.$listeners['row-expanding'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-expanding', e);
      }
    },
    /** @description: 그리드 행이 추가된 이후 이벤트 */
    handleRowInserted(e) {
      if ( this.$listeners['row-inserted'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-inserted', e);
      } else {
        let insertData = {};
        insertData.type = 'insert';
        insertData.key = e.data.id;
        insertData.data = cloneObj(e.data);
        this.changeDatas.push(insertData);
      }
    },
    /** @description: 그리드 행이 추가될 때 이벤트 */
    handleRowInserting(e) {
      if ( this.$listeners['row-inserting'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-inserting', e);
      } else {
        if (e.data.parentId.toString().length > 10) {
          this.$_Toast('새로 추가된 코드의 하위코드는 저장 후 시도하시기 바랍니다.');
          e.cancel = true;
          this.$refs[this.treeListConfig.refName].instance.cancelEditData();
        }
      }
    },
    /** @description: 그리드 행 관련 준비 이벤트 */
    handleRowPrepared(e) {
      if( this.$listeners['row-prepared'] ){ //부모 컴포넌트의 이벤트 호출
        this.$emit('row-prepared', e);
      }
    },
    /** @description: 행이 완전히 삭제된 후에 발생하는 이벤트 */
    handleRowRemoved(e) {
      if ( this.$listeners['row-removed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-removed', e);
      }
    },
    /** @description: 행이 삭제되기 직전에 발생하는 이벤트 */
    handleRowRemoving(e) {
      if ( this.$listeners['row-removing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-removing', e);
      }
    },
    /** @description: 그리드 행이 수정된 후에 발생하는 이벤트 */
    handleRowUpdated(e) {
      if ( this.$listeners['row-updated'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-updated', e);
      } else {
        let updateData = this.changeDatas.find(item => item.key === e.data.id);
        if (updateData) {
          updateData.data = cloneObj(e.data);
        } else {
          updateData = {};
          updateData.type = 'update';
          updateData.key = e.data.id;
          updateData.data = cloneObj(e.data);
          this.changeDatas.push(updateData);
        }
      }
    },
    /** @description: 그리드 행이 수정되기 직전에 발생하는 이벤트 */
    handleRowUpdating(e) {
      if ( this.$listeners['row-updating'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-updating', e);
      }
    },
    /**
     * @description: 그리드 행 유효성 검사 이벤트
     * @param e
     */
    handleRowValidating(e) {
      if (this.$listeners['row-validating']) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('row-validating', e);
      }
    },
    /** @description: 데이터가 성공적으로 저장된 후 발생하는 이벤트 */
    handleSaved(e) {
      if ( this.$listeners['saved'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('saved', e);
      }
    },
    /** @description: 데이터가 저장되기 직전에 발생하는 이벤트  */
    async handleSaving(e) {
      if (this.$listeners['saving']) {
        this.$emit('saving', e);
      } else {
        await this.saveData(e);
      }
    },
    /** @description : 데이터 저장 메서드 */
    async saveData(e) {
      e.cancel = true;
      if (e.changes.length) {
        let data = [];
        if (e.changes && e.changes.length > 0) {
          // 같은 상위코드 내의 하위코드들 코드키 일치 여부 체크
          if (e.changes[0].type === 'insert' && e.changes.length === 1 && e.changes[0].data.parentId !== -1) {
            const newChildCodeKey = e.changes[0].data[this.treeListConfig.key];
            const childList = this.$refs[this.treeListConfig.refName].instance.getNodeByKey(e.changes[0].data.parentId).children;
            if (this.treeListConfig.key) { // 특정 키를 따라가지 않을 시 key를 빼야함
              for (let i = 0; i < childList.length; i++) {
                if (newChildCodeKey !== childList[i].data[this.treeListConfig.key]) {
                  this.$_Toast('같은 상위코드 내의 하위코드는 동일한 코드키로 등록되어야 합니다.');
                  return;
                }
              }
            }
          }
          if (e.changes.length > 1) {
            for (let i = 1; i < e.changes.length; i++) {
              if (e.changes[i - 1].type === 'insert') {
                const parentId1 = e.changes[i - 1].data.parentId;
                const parentId2 = e.changes[i].data.parentId;
                let codeKey1 = e.changes[i - 1].data[this.treeListConfig.key];
                let codeKey2 = e.changes[i].data[this.treeListConfig.key];
                const childLength = this.$refs[this.treeListConfig.refName].instance.getNodeByKey(parentId1).children.length;
                if (this.treeListConfig.key) {
                  if (parentId1 !== -1 &&
                    ((!childLength && (parentId1 === parentId2) && (codeKey1 !== codeKey2)) ||
                      (childLength && (parentId1 === parentId2) && (codeKey1 !== this.$refs[this.treeListConfig.refName].instance.getNodeByKey(parentId1).children[0].data[[this.treeListConfig.key]])))) {
                    this.$_Toast('같은 상위코드 내의 하위코드는 동일한 코드키로 등록되어야 합니다.');
                    return;
                  }
                }
              } else if (e.changes[i - 1].type === 'update') {
                let codeKey1 = e.changes[i - 1].data[this.treeListConfig.key];
                let codeKey2 = e.changes[i].data[this.treeListConfig.key];
                if (this.treeListConfig.key) {
                  if (codeKey1 !== codeKey2) {
                    this.$_Toast('같은 상위코드 내의 하위코드는 동일한 코드키로 등록되어야 합니다.');
                    return;
                  }
                }
              }
            }
          }
          e.changes.forEach(d => {
            const isMerge = !!this.treeListConfig.apiActionNm.merge;
            const keyExpr = this.treeListConfig.keyExpr ? this.treeListConfig.keyExpr : 'id'; // ex) id
            let dataKey = d.key; // ex) 1
            let dataMap = d.data; // ex) { value: 100, name: 'test' }

            // 수정/신규/병합 타입 확인 후 데이터 맵에 등록자/수정자 정보 추가
            if (d.type === 'update') {

              if (isMerge) {
                // 병합은 ROW 전체 데이터로 dataMap 설정
                e.component
                  .byKey(dataKey)
                  .then(data => {
                    dataMap = Object.assign(data, d.data); // Object.assign() 으로 기존 데이터에 변경된 데이터를 덮어씌움
                  })
                  .catch(error => {
                    this.$log.error(error);
                  });
              }
            } else {
              dataKey = null; // 신규일 경우 key 값이 null 이어야 함
            }
            dataMap[keyExpr] = dataKey; // ex) { id: 1, value: 100, name: 'test' }
            data.push(dataMap); // ex) [{ id: 1, value: 100, name: 'test' }]
          });
        } else {
          for (let index = 0; index < this.treeListConfig.dataSource.length; index++) {
            this.treeListConfig.dataSource[index][this.treeListConfig.sortKey] = index;
          }
          data = this.treeListConfig.dataSource;
        }

        const payload = {
          actionname: this.treeListConfig.apiActionNm.merge,
          data: data,
          loading: true,
        };
        const res = await store.dispatch(this.treeListConfig.callApi, payload);

        if (isSuccess(res)) {
          e.component.cancelEditData();
          if (this.treeListConfig.apiActionNm.select) {
            this.handleSelectData();
          } else {
            this.$emit('row-saved', res);
          }
        } else if (res.data.header.resCode === 'fail' && res.data.header.resMsg.includes('.U_CODE_U01')) {
          this.$_Toast('같은 부모의 하위코드 키와 값은 중복될 수 없습니다.');
        } else {
          this.$_Toast('저장 실패');
          e.component.cancelEditData();
        }
      }
    },
    /** @description: 그리드 선택시 변경 관련 이벤트 */
    handleSelectionChanged(e) {
      if ( this.$listeners['selection-changed'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('selection-changed', e);
      } else {
        this.treeListConfig.selectedRowKeys = e.selectedRowKeys;
        this.treeListConfig.selectedRowsData = e.selectedRowsData;
      }
    },
    /** @description: 상단 툴바 버튼 관련 이벤트 */
    handleToolbarPreparing(e) {
      if ( this.$listeners['toolbar-preparing'] ) { // 부모 컴포넌트의 이벤트 호출
        this.$emit('toolbar-preparing', e);
      } else {
        const toolbarItems = e.toolbarOptions.items;

        if (this.treeListConfig.showActionButtons?.update) { //저장, 추가, 취소 버튼 활성화
          toolbarItems.forEach(item => {
            if (item.name === 'addRowButton') {
              item.options.icon = '';
              item.options.text = this.$_msgContents('COMPONENTS.ADD', {defaultValue: '추가'});
              item.options.hint = this.$_msgContents('COMPONENTS.ADD', {defaultValue: '추가'});
              item.showText = 'always';
              item.options.elementAttr = {class: 'btn_XS default filled add1'};
              item.options.width = '60';
              item.options.height = '30';
              item.location = 'before';
              item.sortIndex = 10;
            } else if (item.name === 'saveButton') {
              item.options.icon = '';
              item.options.text = this.$_msgContents('COMPONENTS.SAVE', {defaultValue: '저장'});
              item.options.hint = this.$_msgContents('COMPONENTS.SAVE', {defaultValue: '저장'});
              item.showText = 'always';
              item.options.elementAttr = {class: 'btn_XS default filled', id: this.treeListConfig.saveButtonId};
              item.options.width = '60';
              item.options.height = '30';
              item.location = 'before';
              item.sortIndex = 20;
            } else if (item.name === 'revertButton') {
              item.options.icon = '';
              item.options.text = this.$_msgContents('COMPONENTS.CANCEL', {defaultValue: '취소'});
              item.options.hint = this.$_msgContents('COMPONENTS.CANCEL', {defaultValue: '취소'});
              item.options.elementAttr = {class: 'btn_XS white light_filled '};
              item.showText = 'always';
              item.options.width = '60';
              item.options.height = '30';
              item.location = 'before';
              item.sortIndex = 30;
            }
          });
        } else {
          toolbarItems.forEach(item => {
            if (item.name === 'saveButton') {
              item.options.visible = false;
            } else if (item.name === 'addRowButton') {
              item.options.visible = false;
            } else if (item.name === 'revertButton') {
              item.options.visible = false;
            }
          });
        }

        if( this.treeListConfig.showActionButtons?.delete ) { // 삭제 버튼
          toolbarItems.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('COMPONENTS.DELETE', {defaultValue: '삭제'}),
              hint: this.$_msgContents('COMPONENTS.DELETE', {defaultValue: '삭제'}),
              showText: 'always',
              elementAttr: { class: 'btn_XS white outlined' },
              width: 60,
              height: 30,
              onClick: () => {
                this.handleDeleteData();
              },
            },
            location: 'before',
            sortIndex: 40,
          });
        }

        if( this.treeListConfig.showActionButtons?.sort ) { // 순서 저장 버튼
          toolbarItems.push({
            widget: 'dxButton',
            options: {
              icon: '',
              text: this.$_msgContents('COMPONENTS.SAVE_ORDER', {defaultValue: '순서 저장'}),
              hint: this.$_msgContents('COMPONENTS.SAVE_ORDER', {defaultValue: '순서 저장'}),
              showText: 'always',
              elementAttr: { class: 'btn_XS default filled' },
              height: 30,
              onClick: () => {
                this.handleSaveSort();
              },
            },
            location: 'before',
            sortIndex: 50,
          });
        }

        if( this.treeListConfig.showActionButtons?.toggleExpand ) { // 목록 펼치기/접기 버튼
          toolbarItems.push({
            widget: 'dxButton',
            options: {
              icon: '',
              hint: this.$_msgContents('COMPONENTS.EXPAND_LIST', {defaultValue: '목록 펼치기'}),
              showText: 'always',
              elementAttr: { class: 'btn_XS white outlined folder' },
              height: 30,
              template: (data) => {
                return `<span class='mdi mdi-folder-open'></span>`;
              },
              onClick: () => {
                this.handleOpenTree();
              },
            },
            location: 'before',
            sortIndex: 60,
          });

          toolbarItems.push({
            widget: 'dxButton',
            options: {
              icon: '',
              hint: this.$_msgContents('COMPONENTS.COLLAPSE_LIST', {defaultValue: '목록 접기'}),
              showText: 'always',
              elementAttr: { class: 'btn_XS white outlined folder' },
              height: 30,
              template: (data) => {
                return `<span class='mdi mdi-folder'></span>`;
              },
              onClick: () => {
                this.handleFoldTree();
              },
            },
            location: 'before',
            sortIndex: 61,
          });
        }

        //toolbar custom button push
        if (this.treeListConfig.showActionButtons.customButtons) {
          this.treeListConfig.showActionButtons.customButtons.forEach((d, i) => {
            if (!d.sortIndex) d.sortIndex = Number('7' + (i + 1));
            toolbarItems.push(d);
          });
        }

        e.toolbarOptions.items = toolbarItems.sort((a, b) => a.sortIndex - b.sortIndex);
      }
    },
    /** @description: 사용중인 항목만 보기 변경 이벤트 */
    handleChangedViewFl(e) {
      if (this.$listeners['value-changed']) {
        this.$emit('value-changed', e);
      } else {
        this.treeListConfig.checkedViewFl = e.value;
        if ( this.treeListConfig.checkedViewFl ) {
          this.treeListConfig.dataSource = this.treeListConfig.dataSource.filter(d => d[this.treeListConfig.filterUseItem.key] !== "Y");
        } else {
          this.treeListConfig.dataSource = this.treeListConfig.originDataSource;
        }
      }
    },
    /** @description: 체크박스 디폴트값 true로 세팅*/
    setDefaultCheck() {
      if( this.treeListConfig.filterUseItem.enabled ) {
        this.treeListConfig.checkedViewFl = true;
      }
    },
    /** @description: 순서 저장 */
    handleSaveSort() {
      if (this.treeListConfig.isReordered !== true) {
        return this.$_Toast('순서가 변경된 내역이 없습니다.');
      }
      let changedData = [];
      for (let index = 0; index < this.treeListConfig.dataSource.length; index++) {
        const item = this.treeListConfig.dataSource[index];
        if (item[this.treeListConfig.sortKey] !== index) {
          item[this.treeListConfig.sortKey] = index;
          changedData.push(item);
        }
      }
      this.updateSort(changedData);
    },
    /** @description: 순서 저장 메소드
     *  @param dataList : 저장할 데이터 리스트
     * */
    async updateSort(dataList) {
      const payload = {
        actionname: this.treeListConfig.apiActionNm.merge,
        data: dataList,
        loading: true,
      };
      if (await this.$_Confirm('현재 순서를 저장하시겠습니까?')) {
        const res = await store.dispatch(this.treeListConfig.callApi, payload);
        if (isSuccess(res)) {
          if (this.treeListConfig.apiActionNm.select) {
            this.handleSelectData();
          } else {
            this.$emit('row-saved', res);
          }
          this.treeListConfig.isReordered = false;
          this.$_Toast('적용되었습니다')
        }
      }
    },
    /** @description: 드래그 이벤트 */
    handleDragChangeRow(e) {
      this.treeListConfig.selectedRowKeys = [];
      const visibleRows = e.component.getVisibleRows();
      const sourceNode = e.component.getNodeByKey(e.itemData.id);
      const targetNode = visibleRows[e.toIndex]?.node;

      if (!targetNode || !sourceNode) return; // 유효성 체크

      // 같은 depth가 아니면 드래그 취소
      if (this.getNodeLevel(sourceNode) !== this.getNodeLevel(targetNode)) {
        e.cancel = true;
      }
    },
    /** @description: 노드의 레벨을 반환하는 메소드 */
    getNodeLevel(node) {
      let level = 0;
      while (node.parent) {  // 부모가 존재할 때까지 반복
        level++;
        node = node.parent;
      }
      return level;
    },
    /** @description: 드래그로 데이터 순서 조정 메소드 */
    async handleReorderRow(e) {
      const visibleRows = e.component.getVisibleRows();
      const trees = this.treeListConfig.dataSource;

      if (e.dropInsideItem) {
        e.itemData.parentId = visibleRows[e.toIndex].key;
        e.component.refresh();
      } else {
        const treeList = trees.slice();
        const sourceData = e.itemData;
        const toIndex = e.fromIndex > e.toIndex ? e.toIndex - 1 : e.toIndex;
        let targetData = toIndex >= 0 ? visibleRows[toIndex].node.data : null;

        if (targetData && e.component.isRowExpanded(targetData.id)) {
          sourceData.parentId = targetData.id;
          targetData = null;
        } else {
          sourceData.parentId = targetData ? targetData.parentId : -1;
        }

        const sourceIndex = trees.indexOf(sourceData);
        treeList.splice(sourceIndex, 1);

        const targetIndex = trees.indexOf(targetData) + 1;
        treeList.splice(targetIndex, 0, sourceData);

        await this.reorderTreeList(treeList);

        e.itemData[this.treeListConfig.sortKey] = targetIndex + 1;
        this.treeListConfig.dataSource = treeList;

        this.treeListConfig.isReordered = true;
      }
    },
    /** @description: 트리 데이터 재정렬 메소드 */
    reorderTreeList(newList = []) {
      // reorder
      let order = 1;
      this.treeListConfig.dataSource = newList.map(item => {
        return {...item, [this.treeListConfig.sortKey]: order++};
      });
    },
    /** @description: 트리 목록 펼치기 메소드 */
    handleOpenTree() {
      this.treeListConfig.expandedRowKeys = this.treeListConfig.dataSource.map(d => {
        return d.id;
      });
    },
    /** @description: 트리 목록 접기 메소드 */
    handleFoldTree() {
      this.treeListConfig.expandedRowKeys = [];
    },
    /** @description: 데이터 조회 메소드
     *  @param params : 조회 파라미터
     */
    async handleSelectData(params) {
      this.changeDatas = [];
      params = {sort: this.treeListConfig.dataSourceDefaultSortColumn, ...params};
      const payload = {
        actionname: this.treeListConfig.apiActionNm.select,
        data: params,
        loading: this.treeListConfig.apiActionNm.loading ?? false,
      }
      if (this.treeListConfig.apiActionNm && this.treeListConfig.apiActionNm.select) {
        const res = await this.$store.dispatch(this.treeListConfig.callApi, payload);
        if (isSuccess(res)) {
          this.treeListConfig.dataSource = res.data.data;
          this.treeListConfig.originDataSource = res.data.data;
        } else {
          this.$_Msg(this.$_msgContents('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      }
    },
    /** @description: 데이터 삭제 메서드 */
    async handleDeleteData(data) {
      let selectedRowsData = [];
      if ( data ) { // 단일 데이터
        selectedRowsData = [data.row.node.data];
      } else { // 멀티 데이터
        selectedRowsData = this.treeListConfig.selectedRowsData;
      }

      if (selectedRowsData.length === 0) {
        return this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
      }

      const selectedIds = selectedRowsData.map(d => d.id);
      const childrenIdArr = this.findChildrenById(selectedIds, this.treeListConfig.dataSource);

      const msgContents = this.$_msgContents('CC.MESSAGE.CFM_DELETE_SELECTED_WITH_CHILD', {defaultValue: '선택한 데이터를 삭제하시겠습니까?<br/>하위 데이터도 함께 삭제됩니다.'});
      if (await this.$_Confirm(msgContents)) {
        this.deleteData(childrenIdArr);
      }
    },
    /** @description : 여러개의 id로부터 자신 포함 자식 배열을 가져오는 메서드 */
    findChildrenById(ids, arr) {
      const childrens = new Set(ids); // 여러개의 id를 포함

      function findChildren(parentId) {
        arr.forEach(item => {
          if (item.parentId === parentId) {
            childrens.add(item.id);
            findChildren(item.id); // 재귀 호출로 자식 항목의 자식 항목도 찾음
          }
        });
      }

      ids.forEach(id => findChildren(id));
      return Array.from(childrens);
    },
    /** @description: 데이터 삭제 메소드
     *  @param deletedIds : 삭제할 데이터 리스트
     */
    async deleteData(deletedIds) {
      let res;
      const payload = {
        actionname: this.treeListConfig.apiActionNm.delete,
        data: { data : deletedIds },
        loading: true,
        useErrorPopup: true,
      };
      if (this.treeListConfig.apiActionNm && this.treeListConfig.apiActionNm.delete) {
        res = await this.$store.dispatch(this.treeListConfig.callApi, payload);
      }

      if (isSuccess(res)) {
        if (this.treeListConfig.apiActionNm.select) {
          this.handleSelectData();
        } else {
          this.$emit('row-removed', res);
        }
        this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'));
      } else {
        this.$_Toast(this.$_msgContents('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
      }
    },
    /** @description : 트리 refesh 메서드 */
    refreshData() {
      this.$refs[this.treeListConfig.refName].instance.refresh();
    },
    /** @description : 트리 repaint 메서드 */
    repaintData() {
      this.$refs[this.treeListConfig.refName].instance.repaint();
    },
    /** @description: 그리드의 데이터 없을 경우 출력 */
    noDataText() {
      return this.$_msgContents('COMMON.MESSAGE.CMN_NO_DATA', { defaultValue: '데이터가 없습니다.' });
    },
    /** @description: 높이 설정 */
    setHeight() {
      if (this.treeListConfig.height ?? null === null) {
        let height = this.getTopElement('#dxTreeGrid') + this.getHeightElement('.dx-treelist-header-panel');
        this.treeListConfig.height = 'calc(100vh - ' + height + 'px)';
      }
    },
    /** @description: 상단 위치 정보 */
    getTopElement(e) {
      const divElement = document.querySelector(e);
      const rect = divElement.getBoundingClientRect();
      return rect.top;
    },
    /** @description: element 높이 계산 */
    getHeightElement(e) {
      const divElement = document.querySelector(e);
      const computedStyle = window.getComputedStyle(divElement);
      const divHeight = divElement.offsetHeight;
      const marginTop = parseFloat(computedStyle.marginTop);
      const marginBottom = parseFloat(computedStyle.marginBottom);
      return divHeight + marginTop + marginBottom;
    },
  },
  created() {},
  mounted() {
    if ( this.treeListConfig.apiActionNm?.select ) { // 조회 API 사용시
      this.handleSelectData();
    }

    this.setHeight();
  },
};
</script>
<style scoped>
.sub_new_style01 .page_search_box .inner div {
  display: inline-block;
}

.sub_new_style01 .page_search_box .inner > div {
  vertical-align: middle;
  margin-right: 10px;
}

.dx-checkbox {
  padding-top: 10px;
}

.dx-treelist::v-deep .dx-treelist-content .dx-treelist-table .dx-row > td {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.dx-treelist::v-deep .dx-treelist-header-panel {
  padding: 10px 0;
}

.themes_gp_style01 .wrap main .content .page-sub-box:not(.tree-box) {
  padding: 0 15px 0 15px;
}

::v-deep .btn_XS.folder .dx-button-content {
  line-height: 30px;
}

::v-deep .dx-treelist .dx-link {
  text-decoration: none;
}
</style>